<template>
  <div class="main_content program_emp_search employee_list align-items-center" style="min-height: 80vh">
    <div class="row">
      <!-- First row -->
      <div class="row align-items-center bg_profile px-0 ps-5 ps-md-0">
        <div class="col-lg-8 col-xl-6 col-12 border_side_right">
          <div class="row ">
            <div class="col-sm-2 col-2 text-end px-0">
              <!-- <img :src="require('@/assets/images/dashboard/john_smith_profile.png')" class="img-fluid" width="68" alt=""> -->
              <img :src="base_url + '/avatars/' + employee_data.avatar" class="img-fluid" width="68" alt="" v-if="employee_data.avatar">
            </div>
            <div class="col-sm-10 col-10">
              <div class="main_work">
                <div class="work_area mb-2">
                  <h2 class="d-inline">{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize
                  }}</h2>
                </div>
                <div class="program_list hover_links mb-lg-0 mb-3">
                  <ul class="list-unstyled d-flex flex-wrap mb-0" v-if="$route.name == 'view_employee'">
                    <!-- <li><a href="/user/employee-search">Program Manager Dashboard  </a></li> -->
                    <!-- <li>/<a href="/user/employee-search">  Employee Search </a></li> -->
                    <li> <router-link class="text-darks hov_router" :to="{ name: 'employee_search' }"> Employee
                        Search&nbsp;</router-link></li>
                    <li> / <span class="breadcrumb-item active">&nbsp;{{ employee_data.first_name | capitalize }} {{
                      employee_data.last_name | capitalize }}</span></li>
                    <!-- <li>/<a href="">&nbsp;{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</a></li> -->
                  </ul>
                  <ul class="list-unstyled d-flex flex-wrap mb-0" v-if="$route.name == 'client_employee_view'">
                    <!-- <li><a href="/user/client-manager">PBE Management Dashboard  </a></li> -->
                    <!-- <li> <a :href="'/user/client-employee-search/'+client_uid">Program Management&nbsp;</a></li> -->
                    <li> <router-link class="text-darks hov_router" :to="{ name: 'client_manager' }">Program Management&nbsp;</router-link></li>
                    <li> / <a :href="'/user/client-employee-search/' + client_uid">&nbsp;Employee Search&nbsp;</a></li>
                    <!-- <li> / <a href="">&nbsp;{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</a></li> -->
                    <li> / <span class="breadcrumb-item active breadcrumb_font">&nbsp;{{ employee_data.first_name |
                      capitalize }} {{ employee_data.last_name | capitalize }}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-6 col-12">
          <div
            class="record d-flex flex-column flex-sm-row flex-lg-wrap flex-nowrap justify-content-center justify-content-lg-start">
            <div class="ps-xl-5 ps-md-2 ps-0" v-if="showSelfAssessment">
              <p class="d-inline self_act b_bold">Self-Assessment Activity</p>
              <h3 class="d-inline ms-4">({{ employee_data.self_assessment_count }})</h3>
            </div>
            <div class="ps-0 ps-sm-5">
              <p class="d-inline b_bold">Ergo Eval Activity</p>
              <h3 class="d-inline ms-4">({{ employee_data.ergo_eval_count }})</h3>
            </div>
            <div class="ps-0 ps-sm-5" v-if="showFollowupCount">
              <p class="d-inline b_bold">Follow-Up</p>
              <h3 class="d-inline ms-4">({{ employee_data.followup_count }})</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- Table 1 -->
      <div v-if="showSelfAssessment" class="table_admin mt_50 px-4">
        <div class="col-md-12">
          <div class="table_card">
            <div class="row justify-content-end align-items-center pb_40">
              <div class="col-md-12 ">
                <div class="align-left ">
                  <h3 class="card_heading">Self-Assessment</h3>
                </div>
              </div>
            </div>
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
              <div class="dataTables_length mb-3" id="datatable_length ">
                <label>Reports
                  <select name="datatable_length" aria-controls="datatable" class="" v-model.number="SAItemsPerPage"
                    @change="getSelfAssessmentsearch">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  entries</label>
              </div>
              <div id="datatable_filter" class="dataTables_filter">
                <label><input type="search" class="" aria-controls="datatable" placeholder="Search"
                    v-model="SAListConfig.search_string" v-on:keyup.enter="getSelfAssessmentsearch" />
                  <a v-if="SAListConfig.search_string" href="javascript:void(0)" @click="getSelfAssessmentsearch"
                    class="search_icon slty_up">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </a>
                  <a href="javascript:void(0)" v-if="SAListConfig.search_string" @click="
                    SAListConfig.search_string = null;
                  getSelfAssessmentsearch();
                  " class="crossicon">
                    X
                  </a>
                </label>
              </div>
            </div>
            <div class="table-responsive table_audit_log emp_table_IN">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <th class="sort name_t_col">
                      <SortIcons :lable="'First Name'" :listConfig="SAListConfig" :sortBy="'first_name'"
                        @sorting="SAsorting($event)" />
                    </th>
                    <th class="sort name_t_col">
                      <SortIcons :lable="'Last Name'" :listConfig="SAListConfig" :sortBy="'last_name'"
                        @sorting="SAsorting($event)" />
                    </th>
                    <!-- <th  style="text-align: start !important; width: 30%;" >Name</th> -->
                    <th style="text-align: start !important; width: 30%;">Email ADDRESS </th>
                    <!-- <th class="sort">
                      <SortIcons
                        :lable="'Name'"
                        :listConfig="SAListConfig"
                        :sortBy="'user_name'"
                        @sorting="SAsorting($event)"
                      />
                    </th>
                    <th class="sort">
                      <SortIcons
                        :lable="'Email ADDRESS'"
                        :listConfig="SAListConfig"
                        :sortBy="'user_email'"
                        @sorting="SAsorting($event)"
                      />
                    </th> -->
                    <th class="sort Eavl_date_o_col">
                      <SortIcons :lable="'Date of Eval'" :listConfig="SAListConfig" :sortBy="'assessment_date'"
                        @sorting="SAsorting($event)" />
                    </th>
                    <th class="action_t_col" style="text-align: center !important; width: 10%;">ACTION </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, indx) in SAreports.reports" :key="indx">
                    <td style="text-align:start; width: 15%;">
                      <p>{{ report.first_name }}</p>
                    </td>
                    <td style="text-align:start; width: 15%;">
                      <p>{{ report.last_name }}</p>
                    </td>
                    <td style="text-align:start; width: 30%;">
                      <p class="email_tag"><a :href="'mailto:' + report.user_email">{{ report.user_email }}</a></p>
                    </td>
                    <td style="text-align:start; width: 30%;">
                      <p>{{ report.date }}</p>
                    </td>
                    <td style="text-align:center ;width: 10%;">
                      <div class="edit_btn">
                        <p>
                          <a href="#" class="view-dshboad-btn router-link-exact-active router-link-active p-2"
                            title="Data Set" data-bs-toggle="modal"
                            @click="showReportDataSet(report.dataset, 'Self-Assessment')" data-bs-target="#dataset">
                            <img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')" alt="img" />
                          </a>
                          <!-- <a href="#" aria-current="page"
                            class="view-dshboad-btn router-link-exact-active router-link-active p-2" title="Data set"><img
                              :src="require('@/assets/images/dashboard/Database.png')" alt="img" class="view-icon">
                          </a> -->
                          <!-- <a href="#" aria-current="page"
                            class="view-dshboad-btn router-link-exact-active router-link-active p-2" title="file"><img
                              :src="require('@/assets/images/dashboard/file.png')" alt="img" class="view-icon">
                          </a> -->
                          <router-link :to="{
                            name: employee_report_route,
                            params: { url: report.u_id },
                          }" target="_blank" title="Summary Report">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/file.png')" alt="img" />
                          </router-link>
                          <a href="#" aria-current="page" @click="getSingleReportHistory(report.id)"
                            class="router-link-exact-active router-link-active view-dshboad-btn p-2" title="History"
                            data-bs-toggle="modal" data-bs-target="#history">
                            <img :src="require('@/assets/images/dashboard/timer.png')" alt="img" class="edit-icon">
                          </a>
                          <a :href="'javascript:'"
                            @click="downloadPdfReport(report.summary_report, dashboard, report.full_name)" title="Download">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/download.svg')" alt="img" />
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="sa_data_length == 0">
                    <td colspan="5" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ sa_positionText }}</p>
            <VPagination v-if="sa_data_length" class="dataTables_paginate" :totalPages="sa_pages.length"
              :perPage="SAItemsPerPage" :currentPage.sync="SACurrentPage" @pagechanged="SAonPageChange" />
          </div>
        </div>
      </div>
      <!--Table 2 -->
      <div class="table_admin mt_50 px-4">
        <div class="col-md-12">
          <div class="table_card">
            <div class="row justify-content-end align-items-center pb_40">
              <div class="col-md-12">
                <div class="align-left ">
                  <h3 class="card_heading">Ergo Evals</h3>
                </div>
              </div>
            </div>
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
              <div class="dataTables_length mb-3" id="datatable_length">
                <label>Reports
                  <select name="datatable_length" aria-controls="datatable" class="" v-model.number="EEItemsPerPage"
                    @change="getErgoEvalsearch">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  entries</label>
              </div>
              <div id="datatable_filter" class="dataTables_filter">
                <label><input type="search" class="" aria-controls="datatable" placeholder="Search"
                    v-model="EEListConfig.search_string" v-on:keyup.enter="getErgoEvalsearch" />
                  <a v-if="EEListConfig.search_string" href="javascript:void(0)" @click="getErgoEvalsearch"
                    class="search_icon slty_up">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </a>
                  <a href="javascript:void(0)" v-if="EEListConfig.search_string" @click="
                    EEListConfig.search_string = null;
                  getErgoEvalsearch();
                  " class="crossicon">
                    X
                  </a>
                </label>
              </div>
            </div>
            <div class="table-responsive table_audit_log emp_table_IN">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="name_t_col">
                      <SortIcons :lable="'First Name'" :listConfig="EEListConfig" :sortBy="'first_name'"
                        @sorting="EEsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="name_t_col">
                      <SortIcons :lable="'Last Name'" :listConfig="EEListConfig" :sortBy="'last_name'"
                        @sorting="EEsorting($event)" />
                    </th>
                    <!-- <th  style="text-align: start !important; width: 30%;" >Name</th> -->
                    <th style="text-align: start !important; width: 20%;">Email ADDRESS </th>
                    <!-- <th  style="text-align: start !important; width: 20%;" scope="col">
                     <SortIcons
                        :lable="'Name'"
                        :listConfig="EEListConfig"
                        :sortBy="'user_name'"
                        @sorting="EEsorting($event)"
                      />
                    </th>
                    <th style="text-align: start !important; width: 25%;" scope="col">
                     <SortIcons
                        :lable="'Email Address'"
                        :listConfig="EEListConfig"
                        :sortBy="'user_email'"
                        @sorting="EEsorting($event)"
                      />
                    </th> -->
                    <th style="text-align: start !important; width: 20%;" scope="col" class="DOE_t_col">
                      <SortIcons :lable="'Date of Eval'" :listConfig="EEListConfig" :sortBy="'date_of_evaluation'"
                        @sorting="EEsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 20%;" scope="col" class="evaluator_t_col">
                      <SortIcons :lable="'Evaluator'" :listConfig="EEListConfig" :sortBy="'evaluator'"
                        @sorting="EEsorting($event)" />
                    </th>
                    <th scope="col" class="sort name_evaluator move_icon">
                      <SortIcons :lable="'Status'" :listConfig="EEListConfig" :sortBy="'status'"
                        @sorting="EEsorting($event)" />
                      <svg style="margin-left: 10px"
                        v-tooltip="'Re-Review, In Review, Declined, Finalized, Evaluator Reviewed, Evaluator QC Reviewed, Hidden & Pending Client Approval'"
                        xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                        viewBox="0 0 14 18.346">
                        <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                        <path data-name="Path 9427"
                          d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                          transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                      </svg>
                    </th>
                    <th style="text-align: center !important; width: 10%;" scope="col" class="action_t_col">ACTION </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, indx) in EEreports.reports" :key="indx">
                    <td style="text-align: start !important; width: 10%;">
                      <p>{{ report.first_name }}</p>
                    </td>
                    <td style="text-align: start !important; width: 10%;">
                      <p>{{ report.last_name }}</p>
                    </td>
                    <td style="text-align: start !important; width: 25%;">
                      <p class="email_tag"><a :href="'mailto:' + report.user_email">{{ report.user_email }}</a></p>
                    </td>
                    <td style="text-align: start !important; width: 25%;">
                      <p>{{ report.date }}</p>
                    </td>
                    <td style="text-align: start !important; width: 20%;">
                      <p>{{ report.evaluator }} <span v-if="report.evaluatorExists != ''"><br><small>(User does not exists
                            in our system)</small></span></p>
                      <!-- <p v-if="report.validEvaluator">{{ report.evaluator }}</p> -->
                      <!-- <p v-else v-tooltip="'This evaluator is not found'">{{ report.evaluator }}</p> -->
                    </td>
                    <td>
                      <p v-bind:class="switchStatusColor(report.status)">
                        {{ report.status }}
                      </p>
                    </td>
                    <td style="text-align: center !important; width: 10%;">
                      <div class="edit_btn">
                        <p>
                          <a href="#" @click="showReportDataSet(report.dataset, 'Ergo eval')"
                            class="view-dshboad-btn router-link-exact-active router-link-active p-2" title="Data Set"
                            data-bs-toggle="modal" data-bs-target="#dataset">
                            <img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')" alt="img" />
                          </a>
                          <router-link :to="{
                            name: employee_report_route,
                            params: { url: report.u_id },
                          }" target="_blank" title="Summary Report" class="p-2">
                            <img class="edit-icon " :src="require('@/assets/images/dashboard/file.png')" alt="img" />
                          </router-link>
                          <a href="#" aria-current="page" @click="getSingleReportHistory(report.id)"
                            v-if="employee_search"
                            class="router-link-exact-active router-link-active view-dshboad-btn p-2" title="History"
                            data-bs-toggle="modal" data-bs-target="#history">
                            <img :src="require('@/assets/images/dashboard/timer.png')" alt="img" class="edit-icon">
                          </a>
                          <a class="p-2" :href="'javascript:'"
                            @click="downloadPdfReport(report.summary_report, dashboard, report.full_name)" title="Download">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/download.svg')" alt="img" />
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="ee_data_length == 0">
                    <td colspan="6" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ ee_positionText }}</p>
            <VPagination v-if="ee_data_length" class="dataTables_paginate" :totalPages="ee_pages.length"
              :perPage="EEItemsPerPage" :currentPage.sync="EECurrentPage" @pagechanged="EEonPageChange" />
          </div>
        </div>
      </div>
      <!--Table 3 -->
      <div v-if="showFollowUp" class="table_admin mt_50 px-4">
        <div class="col-md-12">
          <div class="table_card">
            <div class="row justify-content-end align-items-center pb_40">
              <div class="col-md-12">
                <div class="align-left ">
                  <h3 class="card_heading">Follow-Ups</h3>
                </div>
              </div>
            </div>
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
              <div class="dataTables_length mb-3" id="datatable_length ">
                <label>Reports
                  <select name="datatable_length" aria-controls="datatable" class="" v-model.number="followupItemsPerPage"
                    @change="getFollowupsearch">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  entries</label>
              </div>
              <div id="datatable_filter" class="dataTables_filter">
                <label><input type="search" class="" aria-controls="datatable" placeholder="Search"
                    v-model="followupListConfig.search_string" v-on:keyup.enter="getFollowupsearch" />
                  <a v-if="followupListConfig.search_string" href="javascript:void(0)" @click="getFollowupsearch"
                    class="search_icon slty_up">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </a>
                  <a href="javascript:void(0)" v-if="followupListConfig.search_string" @click="
                    followupListConfig.search_string = null;
                  getFollowupsearch();
                  " class="crossicon">
                    X
                  </a>
                </label>
              </div>
            </div>
            <div class="table-responsive table_audit_log emp_table_IN">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="name_t_col">
                      <SortIcons :lable="'First Name'" :listConfig="followupListConfig" :sortBy="'first_name'"
                        @sorting="followupsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="name_t_col">
                      <SortIcons :lable="'Last Name'" :listConfig="followupListConfig" :sortBy="'last_name'"
                        @sorting="followupsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 30%;">Email ADDRESS </th>
                    <!-- <th  style="text-align: start !important; width: 20%;" scope="col">
                     <SortIcons
                        :lable="'Email Address'"
                        :listConfig="followupListConfig"
                        :sortBy="'user_email'"
                        @sorting="followupsorting($event)"
                      />
                    </th> -->
                    <th style="text-align: start !important; width: 15%;" scope="col" class="emp_flw_col">
                      <SortIcons :lable="'Date of Follow-Up'" :listConfig="followupListConfig" :sortBy="'created_at'"
                        @sorting="followupsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 15%;">
                      <SortIcons :lable="'Status'" :listConfig="followupListConfig" :sortBy="'status'"
                        @sorting="followupsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 10%;" scope="col" class="action_t_col">ACTION </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, indx) in followupreports" :key="indx">
                    <td style="text-align: start !important; width: 15%;">
                      <p>{{ report.first_name }}</p>
                    </td>
                    <td style="text-align: start !important; width: 15%;">
                      <p>{{ report.last_name }}</p>
                    </td>
                    <td style="text-align: start !important; width: 30%;">
                      <p class="email_tag"><a :href="'mailto:' + report.user_email">{{ report.user_email }}</a></p>
                    </td>
                    <td style="text-align: start !important; width: 30%;">
                      <p>{{ report.formated_date }}</p>
                    </td>
                    <td style="text-align: start !important; width: 30%;">
                      <p class="email_tag"> {{ report.status }} </p>
                    </td>
                    <td style="text-align: start !important; width: 30%;">
                      <div class="edit_btn follow_ml">
                        <p>
                          <a href="#" @click="showReportDataSet(report.dataset, 'Follow up')"
                            class="view-dshboad-btn router-link-exact-active router-link-active p-2" title="Data Set"
                            data-bs-toggle="modal" data-bs-target="#dataset">
                            <img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')" alt="img" />
                          </a>
                          <!-- <a href="#" aria-current="page"
                            class="view-dshboad-btn router-link-exact-active router-link-active p-2" title="Data set"><img
                              :src="require('@/assets/images/dashboard/Database.png')" alt="img" class="view-icon">
                          </a> -->
                          <a href="#" aria-current="page" @click="getSingleFollowupHistory(report.id)"
                            class="router-link-exact-active router-link-active view-dshboad-btn p-2" title="History"
                            data-bs-toggle="modal" data-bs-target="#history">
                            <img :src="require('@/assets/images/dashboard/timer.png')" alt="img" class="edit-icon">
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="followup_data_length == 0">
                    <td colspan="5" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ followup_positionText }}</p>
            <VPagination v-if="followup_data_length" class="dataTables_paginate" :totalPages="followup_pages.length"
              :perPage="followupItemsPerPage" :currentPage.sync="followupCurrentPage"
              @pagechanged="followuponPageChange" />
          </div>
        </div>
      </div>
      <!--Table 4 -->
      <div v-if="showScheduledRequest" class="table_admin mt_50 px-4 mb-5">
        <div class="col-md-12">
          <div class="table_card">
            <div class="row justify-content-end align-items-center pb_40">
              <div class="col-md-12">
                <div class="align-left ">
                  <h3 class="card_heading">Schedule Request Forms</h3>
                </div>
              </div>
            </div>
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
              <div class="dataTables_length mb-3" id="datatable_length">
                <label>Reports
                  <select name="datatable_length" aria-controls="datatable" class="" v-model.number="SRItemsPerPage"
                    @change="getScheduleRequestsearch">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  entries</label>
              </div>
              <div id="datatable_filter" class="dataTables_filter">
                <label><input type="search" class="" aria-controls="datatable" placeholder="Search"
                    v-model="SRListConfig.search_string" v-on:keyup.enter="getScheduleRequestsearch" />
                  <a v-if="SRListConfig.search_string" href="javascript:void(0)" @click="getScheduleRequestsearch"
                    class="search_icon slty_up">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </a>
                  <a href="javascript:void(0)" v-if="SRListConfig.search_string" @click="
                    SRListConfig.search_string = null;
                  getScheduleRequestsearch();
                  " class="crossicon">
                    X
                  </a>
                </label>
              </div>
            </div>
            <div class="table-responsive table_audit_log emp_table_IN">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="name_t_col">
                      <SortIcons :lable="'First Name'" :listConfig="SRListConfig" :sortBy="'first_name'"
                        @sorting="SRsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="name_t_col">
                      <SortIcons :lable="'Last Name'" :listConfig="SRListConfig" :sortBy="'last_name'"
                        @sorting="SRsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 30%;">Email ADDRESS </th>
                    <!-- <th  style="text-align: start !important; width: 20%;" scope="col">
                     <SortIcons
                        :lable="'Email Address'"
                        :listConfig="SRListConfig"
                        :sortBy="'user_email'"
                        @sorting="SRsorting($event)"
                      />
                    </th> -->
                    <th style="text-align: start !important; width: 15%;" scope="col" class="DOR_t_col">
                      <SortIcons :lable="'Date of request'" :listConfig="SRListConfig" :sortBy="'created_at'"
                        @sorting="SRsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="DOR_t_col">
                      <SortIcons :lable="'Status'" :listConfig="SRListConfig" :sortBy="'status'"
                        @sorting="SRsorting($event)" />
                    </th>
                    <th style="text-align: start !important; width: 10%;" scope="col" class="last_action_t_col">ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, indx) in SRreports" :key="indx">
                    <td style="text-align: start !important; width: 15%;">
                      <p>{{ report.first_name }}</p>
                    </td>
                    <td style="text-align: start !important; width: 15%;">
                      <p>{{ report.last_name }}</p>
                    </td>
                    <td style="text-align: start !important; width: 30%;">
                      <p class="email_tag"><a :href="'mailto:' + report.user_email">{{ report.user_email }}</a></p>
                    </td>
                    <td style="text-align: start !important; width: 30%;">
                      <p>{{ report.formated_date }}</p>
                    </td>
                    <td style="text-align: start !important; width: 30%;">
                      <p>{{ report.status }}</p>
                    </td>
                    <td style="text-align: start !important; width: 10%;">
                      <div class="edit_btn follow_ml">
                        <p>
                          <a href="#" @click="showReportDataSet(report.dataset, 'Scheduled Request')"
                            class="view-dshboad-btn router-link-exact-active router-link-active p-2" title="Data Set"
                            data-bs-toggle="modal" data-bs-target="#dataset">
                            <img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')" alt="img" />
                          </a>
                          <a href="#" aria-current="page" @click="getSingleRequestHistory(report.id)"
                            class="router-link-exact-active router-link-active view-dshboad-btn p-2" title="History"
                            data-bs-toggle="modal" data-bs-target="#history">
                            <img :src="require('@/assets/images/dashboard/timer.png')" alt="img" class="edit-icon">
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="sr_data_length == 0">
                    <td colspan="5" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ sr_positionText }}</p>
            <VPagination v-if="sr_data_length" class="dataTables_paginate" :totalPages="sr_pages.length"
              :perPage="SRItemsPerPage" :currentPage.sync="SRCurrentPage" @pagechanged="SRonPageChange" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="modal box_up fade" id="history" tabindex="-1" aria-labelledby="downloadLabel" ref="historyPopup"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="downloadLabel">
                <h2 class="heading_2 font_size-20">History</h2>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <Spinner v-show="!showHistory" ref="Spinner"></Spinner>
            <div class="modal-body details_eval">
              <div class="row justify-content-center">
                <div class="col-xs-10 col-md-10 main-timeline cust_scroll" >
                  <div v-show="showHistory">
                    <div class="timeline " v-if="allCommentArr.length > 0" >
                      <div :class=" indx%2==0 ? 'container_timeline left' : 'container_timeline right' " v-for="(comment, indx) in allCommentArr" :key="indx">
                        <div class="date date_set">{{comment.commented_date}}</div>
                        <div class="content">
                          <h1>{{ comment.history_key }}</h1>
                          <h2>{{ comment.history_value }}</h2>
                          <h6> By: {{ comment.user_name }} </h6>
                          <h6 v-if="!comment.user_exist">(User does not exists in our system)</h6>
                        </div>
                      </div>
                    </div>
                    <div v-else class="row">
                      <div class="col-xs-12 col-md-12">
                        <h2 class="text-center no_data mb-5">There is no history available</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div> -->
    <DatasetModal v-bind:downloadMessage="downloadMessage" v-bind:dashboard="dashboard" v-bind:dataSet="dataSet"
      v-bind:isEmptyDataSet="isEmptyDataSet" ref="datasetModal"></DatasetModal>
    <HistoryModal v-bind:history="allCommentArr" v-bind:isShowHistory="showHistory" ref="historyModal"></HistoryModal>
  </div>
</template>
<script>
import $ from "jquery";
import "vue-select/dist/vue-select.css";
import AuthService from "@/services/AuthService";
import VPagination from "@/components/VPagination";
import DatasetModal from "@/components/DatasetModal";
import HistoryModal from "@/components/HistoryModal";
import SortIcons from "@/components/SortingIcons";
import commonFunction from '../../mixin/commonFunction'

export default {
  name: "view_employee",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    DatasetModal,
    HistoryModal
  },
  data() {
    return {
      commonLoader: false,
      allLoader: false,
      dataSet: [],
      downloadMessage: 'Dataset',
      dashboard: 'Employee Search',
      isEmptyDataSet: true,
      showDataset: false,
      commentArr: [],
      addCommentArr: {
        report_id: null,
        comments: null,
      },
      allCommentArr: [],
      base_url: '',
      employee_report_route: '',
      client_uid: '',
      show: false,
      reportAvailable: true,
      showHistory: false,
      first_name: "",
      isDisabled: true,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      employee_data: [],
      reportData: [],
      //SA Table Data
      SAreports: [],
      SACurrentPage: 1,
      SAItemsPerPage: 10,
      SAListConfig: {
        offset: (this.SACurrentPage - 1) * this.SAItemsPerPage,
        limit: this.SAItemsPerPage,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
        employee_id: null,
      },
      sa_data_length: 0,

      //EE Table Data
      EEreports: [],
      EECurrentPage: 1,
      EEItemsPerPage: 10,
      EEListConfig: {
        offset: (this.EECurrentPage - 1) * this.EEItemsPerPage,
        limit: this.EEItemsPerPage,
        sortBy: "date_of_evaluation",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
        employee_id: null,
      },
      ee_data_length: 0,

      //SR Table Data
      SRreports: [],
      SRCurrentPage: 1,
      SRItemsPerPage: 10,
      SRListConfig: {
        offset: (this.SRCurrentPage - 1) * this.SRItemsPerPage,
        limit: this.SRItemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
        employee_id: null,
      },
      sr_data_length: 0,

      //followup Table Data
      followupreports: [],
      followupCurrentPage: 1,
      followupItemsPerPage: 10,
      followupListConfig: {
        offset: (this.followupCurrentPage - 1) * this.followupItemsPerPage,
        limit: this.followupItemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
        employee_id: null,
      },
      followup_data_length: 0,
      employee_search: false,
      showSelfAssessment: true,
      showFollowUp: true,
      showScheduledRequest: true,
      showFollowupCount:false,
    };
  },
  computed: {
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    sa_pages() {
      if (this.sa_data_length <= this.SAItemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.sa_data_length / this.SAItemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    sa_positionText: function () {
      var endIndex = this.SACurrentPage * this.SAItemsPerPage,
        startIndex = (this.SACurrentPage - 1) * this.SAItemsPerPage + 1;
      if (this.sa_data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.sa_data_length ? this.sa_data_length : endIndex) +
        " of " +
        this.sa_data_length
      );
    },
    ee_pages() {
      if (this.ee_data_length <= this.EEItemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.ee_data_length / this.EEItemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    ee_positionText: function () {
      var endIndex = this.EECurrentPage * this.EEItemsPerPage,
        startIndex = (this.EECurrentPage - 1) * this.EEItemsPerPage + 1;
      if (this.ee_data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.ee_data_length ? this.ee_data_length : endIndex) +
        " of " +
        this.ee_data_length
      );
    },
    sr_pages() {
      if (this.sr_data_length <= this.SRItemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.sr_data_length / this.SRItemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    sr_positionText: function () {
      var endIndex = this.SRCurrentPage * this.SRItemsPerPage,
        startIndex = (this.SRCurrentPage - 1) * this.SRItemsPerPage + 1;
      if (this.sr_data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.sr_data_length ? this.sr_data_length : endIndex) +
        " of " +
        this.sr_data_length
      );
    },
    followup_pages() {
      if (this.followup_data_length <= this.followupItemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.followup_data_length / this.followupItemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    followup_positionText: function () {
      var endIndex = this.followupCurrentPage * this.followupItemsPerPage,
        startIndex = (this.followupCurrentPage - 1) * this.followupItemsPerPage + 1;
      if (this.followup_data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.followup_data_length ? this.followup_data_length : endIndex) +
        " of " +
        this.followup_data_length
      );
    },
  },
  destroyed() {
    this.$parent.clientManagerDashboard = false;
    this.$parent.employeeDashboard = false;
  },
  beforeMount() {
    if (this.$route.params.id != undefined) {
      this.employee_id = this.$route.params.id;
      this.buttonUpdate = true;
      this.getEmployeeDetail(this.$route.params.id, this.$route.params.clientid);
    }
  },
  watch: {
    SACurrentPage(newValue) {
      this.SAListConfig.offset = (newValue - 1) * this.SAItemsPerPage;
      this.getSelfAssessmentReports();
    },
    EECurrentPage(newValue) {
      this.EEListConfig.offset = (newValue - 1) * this.EEItemsPerPage;
      this.getErgoEvalReports();
    },
    SRCurrentPage(newValue) {
      this.SRListConfig.offset = (newValue - 1) * this.SRItemsPerPage;
      this.getScheduleRequestReports();
    },
    followupCurrentPage(newValue) {
      this.followupListConfig.offset = (newValue - 1) * this.followupItemsPerPage;
      this.getFollowupReports();
    },
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    var clientData = this.$store.getters["auth/authUser"];
    this.base_url = process.env.VUE_APP_API_URL;
    if (this.$router.currentRoute.name == 'client_employee_view') {
      this.dashboard = 'Program Management Employees';
      this.$parent.clientManagerDashboard = true;
      this.employee_report_route = 'client_employee_reports';
      this.employee_search = true;
    }
    else {
      this.dashboard = 'Employee Search';
      if (clientData.client.client_type != 1) {
        this.showSelfAssessment = false;
        this.showScheduledRequest = false;
          if(clientData.client.client_type == 2){
            this.showFollowupCount = true;
          }
      }
      if (clientData.client.client_type == 3) {
        this.showFollowUp = false;
      }
      this.$parent.employeeDashboard = true;
      this.employee_report_route = 'view_employee_reports';
      this.employee_search = false;
    }
  },
  methods: {
    showReportDataSet(dataSet, type) {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = true;
        if (dataSet != null) {
          var i = 1;
          for (const item in dataSet) {
            dataSet[item].id = i++;
            if (dataSet[item].options) {
              for (const item1 in dataSet[item].options) {
                if (dataSet[item].options[item1].answer) {
                  if (dataSet[item].answer)
                    dataSet[item].answer += ', ' + dataSet[item].options[item1].answer;
                  else
                    dataSet[item].answer = dataSet[item].options[item1].answer;
                }
              }
            }
            if (dataSet[item].subquestions) {
              for (const item2 in dataSet[item].subquestions) {
                if (dataSet[item].subquestions[item2].answer) {
                  if (dataSet[item].answer) {
                    dataSet[item].answer += ', ' + dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                  else {
                    dataSet[item].answer = dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                }
              }
            }
          }
          this.dataSet = dataSet;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
          this.isEmptyDataSet = Object.keys(dataSet).length === 0;
          this.downloadMessage = "Dataset of " + type + " for " + this.employee_data.first_name_camel + " " + this.employee_data.last_name_camel;
          if (this.$router.currentRoute.name == 'client_employee_view') {
            this.dashboard = 'Program Management Employees';
          } else {
            this.dashboard = 'Employee Search';
          }
        } else {
          this.dataSet = [];
          this.isEmptyDataSet = true;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        }
      } else {
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        $('.modal-backdrop').remove()
        $(document.body).removeClass("modal-open");
        this.tokenExpired();
      }
    },
    getSingleReportHistory(reportid) {
      if (reportid) {
        this.showHistory = false;
        this.commentArr = [];
        this.allCommentArr = [];
        this.addCommentArr.report_id = reportid;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          // $('#history').modal('show');
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/reports-history",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(_this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.allCommentArr = decodedJson.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              this.allCommentArr = [];
              if (response.status == 401) {
                $('.modal-backdrop').remove()
                $(document.body).removeClass("modal-open");
                this.tokenExpired();
              }
            });
        } else {
          $('.modal-backdrop').remove()
          $(document.body).removeClass("modal-open");
          this.tokenExpired();
        }
      }
    },
    getSingleFollowupHistory(reportid) {
      if (reportid) {
        this.commentArr = [];
        this.allCommentArr = [];
        this.showHistory = false;
        this.addCommentArr.report_id = reportid;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          // $('#history').modal('show');
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/followup-history",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(_this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.allCommentArr = decodedJson.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              this.allCommentArr = [];
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              if (response.status == 401) {
                $('.modal-backdrop').remove()
                $(document.body).removeClass("modal-open");
                this.tokenExpired();
              }
            });
        } else {
          $('.modal-backdrop').remove()
          $(document.body).removeClass("modal-open");
          this.tokenExpired();
        }
      }
    },
    getSingleRequestHistory(reportid) {
      if (reportid) {
        this.commentArr = [];
        this.allCommentArr = [];
        this.showHistory = false;
        this.addCommentArr.report_id = reportid;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          // $('#history').modal('show');
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/request-history",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(_this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.allCommentArr = decodedJson.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              _this.allCommentArr = [];
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              if (response.status == 401) {
                $('.modal-backdrop').remove()
                $(document.body).removeClass("modal-open");
                this.tokenExpired();
              }
            });
        } else {
          $('.modal-backdrop').remove()
          $(document.body).removeClass("modal-open");
          this.tokenExpired();
        }
      }
    },
    downloadZipFile(url, username, type_of_assessment) {
      var fileName = "report";
      if (type_of_assessment == "Self-Assessment") {
        fileName = username + "-self-assessment.html";
      } else if (type_of_assessment == "Ergo Eval") {
        fileName = username + "-ergo-eval.html";
      }
      AuthService.downloadZipFile(url, username).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    SAonPageChange(page) {
      this.SACurrentPage = page;
    },
    EEonPageChange(page) {
      this.EECurrentPage = page;
    },
    followuponPageChange(page) {
      this.followupCurrentPage = page;
    },
    SRonPageChange(page) {
      this.SRCurrentPage = page;
    },
    SAsorting($event) {
      this.SAListConfig.sortBy == $event.sortBy;
      this.SAListConfig.order == $event.order;
      this.getSelfAssessmentReports();
    },
    EEsorting($event) {
      this.EEListConfig.sortBy == $event.sortBy;
      this.EEListConfig.order == $event.order;
      this.getErgoEvalReports();
    },
    SRsorting($event) {
      this.SRListConfig.sortBy == $event.sortBy;
      this.SRListConfig.order == $event.order;
      this.getScheduleRequestReports();
    },
    followupsorting($event) {
      this.followupListConfig.sortBy == $event.sortBy;
      this.followupListConfig.order == $event.order;
      this.getFollowupReports();
    },
    getSelfAssessmentsearch() {
      this.SACurrentPage = 1;
      this.SAListConfig.limit = this.SAItemsPerPage;
      this.getSelfAssessmentReports();
    },
    getErgoEvalsearch() {
      this.EECurrentPage = 1;
      this.EEListConfig.limit = this.EEItemsPerPage;
      this.getErgoEvalReports();
    },
    getFollowupsearch() {
      this.followupCurrentPage = 1;
      this.followupListConfig.limit = this.followupItemsPerPage;
      this.getFollowupReports();
    },
    getScheduleRequestsearch() {
      this.SRCurrentPage = 1;
      this.SRListConfig.limit = this.SRItemsPerPage;
      this.getScheduleRequestReports();
    },
    getEmployeeDetail(id, clientid) {
      var apiUrl;
      var clientUrlId = "";
      //var redirectUrl;
      if (this.$router.currentRoute.name == 'client_employee_view') {
        apiUrl = "/api/client-view-employee/";
        // redirectUrl = 'client_manager';
        clientUrlId = "/" + clientid;
      } else {
        apiUrl = "/api/view-employee/";
        //redirectUrl = 'employee_search';
      }
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + apiUrl + id + clientUrlId,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.employee_data = decodedJson.records;
            if (decodedJson.records.active_status === 1) {
              _this.employee_data.active_status = true;
            }
            if (decodedJson.records.temporary_access != '' && decodedJson.records.temporary_access != null && decodedJson.records.temporary_access != '0000-00-00') {
              _this.employee_data.temporary_access = new Date(decodedJson.records.temporary_access);
            }
            _this.employee_data.client_ids = JSON.parse(decodedJson.records.client_ids);
            if (decodedJson.client_uid) {
              _this.client_uid = decodedJson.client_uid;
            }
            if (Object.keys(_this.employee_data).length > 0) {
              _this.SAListConfig = {
                offset: (_this.SACurrentPage - 1) * _this.SAItemsPerPage,
                limit: _this.SAItemsPerPage,
                sortBy: "assessment_date",
                order: "DESC",
                search_string: null,
                start_date: null,
                end_date: null,
                employee_id: _this.employee_id,
              };
              _this.EEListConfig = {
                offset: (_this.EECurrentPage - 1) * _this.EEItemsPerPage,
                limit: _this.EEItemsPerPage,
                sortBy: "date_of_evaluation",
                order: "DESC",
                search_string: null,
                start_date: null,
                end_date: null,
                employee_id: _this.employee_id,
              };
              _this.SRListConfig = {
                offset: (_this.SRCurrentPage - 1) * _this.SRItemsPerPage,
                limit: _this.SRItemsPerPage,
                sortBy: "created_at",
                order: "DESC",
                search_string: null,
                start_date: null,
                end_date: null,
                employee_id: _this.employee_id,
              };
              _this.followupListConfig = {
                offset: (_this.followupCurrentPage - 1) * _this.followupItemsPerPage,
                limit: _this.followupItemsPerPage,
                sortBy: "created_at",
                order: "DESC",
                search_string: null,
                start_date: null,
                end_date: null,
                employee_id: _this.employee_id,
              };

              _this.getSelfAssessmentReports();
              _this.getErgoEvalReports();
              _this.getFollowupReports();
              _this.getScheduleRequestReports();

            }
          }
        })
        .catch(({ response }) => {
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }

          if (response.status == 403) {
            _this.$router.push({ name: "403" });
          }

        });
    },

    getSelfAssessmentReports() {
      var _this = this;
      if (localStorage.getItem("userToken")) {
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        _this.isLoader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/self-assessment-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: { request_data: this.encodeAPIRequestWithImages(_this.SAListConfig) },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.first_name = decodedJson.first_name;
            _this.SAreports = decodedJson;
            _this.sa_data_length = decodedJson.counts;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.commonLoader = true;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.commonLoader = true;
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.tokenExpired();
            }
            if (response.status == 404) {
              this.$router.push({ name: "404" });
            }
          });
      } else {
        _this.tokenExpired();
      }
    },
    getErgoEvalReports() {
      var _this = this;
      if (localStorage.getItem("userToken")) {
        if (_this.commonLoader) {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        }
        _this.isLoader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/ergo-eval-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: { request_data: this.encodeAPIRequestWithImages(_this.EEListConfig) },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.first_name = decodedJson.first_name;
            _this.EEreports = decodedJson;
            _this.ee_data_length = decodedJson.counts;
            if (_this.commonLoader) {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            }
          })
          .catch(({ response }) => {
            if (_this.commonLoader) {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            }
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.tokenExpired();
            }
          });
      } else {
        _this.tokenExpired();
      }
    },
    getFollowupReports() {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        if (_this.commonLoader) {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        }
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/followup-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: { request_data: this.encodeAPIRequestWithImages(_this.followupListConfig) },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.followupreports = decodedJson.allResponses;
            _this.followup_data_length = decodedJson.counts;
            if (_this.commonLoader) {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            }
          })
          .catch(({ response }) => {
            if (_this.commonLoader) {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            }
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.tokenExpired();
            }
            if (response.status == 404) {
              this.$router.push({ name: "404" });
            }
          });
      } else {
        this.tokenExpired();
      }
    },
    getScheduleRequestReports() {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        if (_this.commonLoader) {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        }
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/schedule-request-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: { request_data: this.encodeAPIRequestWithImages(_this.SRListConfig) },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.SRreports = decodedJson.allResponses;
            _this.sr_data_length = decodedJson.counts;
            if (_this.commonLoader) {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            }
          })
          .catch(({ response }) => {
            if (_this.commonLoader) {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            }
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.tokenExpired();
            }
            if (response.status == 404) {
              this.$router.push({ name: "404" });
            }
          });
      } else {
        this.tokenExpired();
      }
    }
  },
};
</script>